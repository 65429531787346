






import { defineComponent, onMounted, reactive } from '@nuxtjs/composition-api';
import { SfButton, SfTopBar } from '@storefront-ui/vue';
import { useTopBar } from './useTopBar';
import { useFetch, ref } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';

export default defineComponent({
  components: {
    CurrencySelector: () => import('../CurrencySelector.vue'),
    StoreSwitcher: () => import('../StoreSwitcher.vue'),
    SfTopBar,
    SfButton,
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const { loadBlocks } = useContent();
    const blocks = ref([]);
    
    const menu = reactive({
      top: null
    });

    function test(event) {

      console.log('teste333dd', event)
    }
  
    useFetch(async () => {
      try {
        blocks.value = await loadBlocks({identifiers: ['top-menu']});
        menu.top = blocks.value[0].content;
      } catch (error) {
        menu.top = null;
      }
    });
    
    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      blocks,
      menu
    };
  },
  methods: {
    clickMenu(event) {
      event.preventDefault();
      
      console.log('teste333', event)
    }
  }
});

