var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SfHeader',{staticClass:"sf-header--has-mobile-search custom-app-header",class:{ 'header-on-top': _vm.isSearchOpen },scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('HeaderLogo')]},proxy:true},{key:"search",fn:function(){return [_c('SearchBar',{attrs:{"is-search-open":_vm.isSearchOpen},on:{"set-is-open":function($event){_vm.isSearchOpen = $event},"set-search-results":function($event){_vm.productSearchResults = $event}}})]},proxy:true},{key:"navigation",fn:function(){return [_c('label',[_c('SfSelect',{staticClass:"company-select",attrs:{"size":"lg","placeholder":"Selecione o CNPJ"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            Você está comprando para: \n          ")]},proxy:true},{key:"chevron",fn:function(){return [_c('SfIconUnfoldMore',{staticClass:"absolute pointer-events-none top-2 right-4 text-neutral-500"})]},proxy:true}]),model:{value:(_vm.cnpjSelected),callback:function ($$v) {_vm.cnpjSelected=$$v},expression:"cnpjSelected"}},[_vm._v(" "),_vm._v(" "),_vm._l((_vm.options),function(ref){
var value = ref.value;
var label = ref.label;
return _c('option',{key:value,domProps:{"value":value}},[_vm._v("\n            "+_vm._s(label)+"\n          ")])})],2)],1)]},proxy:true},{key:"header-icons",fn:function(ref){
var activeIcon = ref.activeIcon;
return [_c('div',{staticClass:"sf-header__icons"},[_c('div',[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure sf-header__action btn-account",attrs:{"data-testid":"accountIcon","aria-label":"Account"},on:{"click":_vm.handleAccountClick}},[_c('SvgImage',{staticClass:"color-white",class:{
              'sf-header__icon is-active': activeIcon === 'account',
            },attrs:{"icon":_vm.accountIcon,"label":_vm.$t('Account'),"width":"1.25rem","height":"1.25rem"}}),_vm._v(" "),_c('label',{staticClass:"sf-header__action_label color-white"},[_vm._v("\n            MINHA CONTA\n          ")])],1)],1),_vm._v(" "),_c('div',[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-cart'),expression:"'app-header-cart'"}],staticClass:"sf-button--pure sf-header__action",attrs:{"aria-label":"Toggle cart sidebar"},on:{"click":_vm.toggleCartSidebar}},[_c('SvgImage',{staticClass:"sf-header__icon",class:{
                'sf-header__icon is-active': activeIcon === 'cart',
              },attrs:{"icon":"empty_cart","label":_vm.$t('Cart'),"width":"40","height":"40"}}),_vm._v(" "),_c('label',{staticClass:"sf-header__action_label color-white"},[_vm._v("\n              "+_vm._s(_vm.cartTotalItems)+" item(s)\n            ")])],1)],1)])]}}])}),_vm._v(" "),_c('div',{staticClass:"custom-header-navigation desktop-only"},[_c('div',{staticStyle:{"width":"80%","margin":"0 auto"}},[_c('HeaderNavigation',{attrs:{"category-tree":_vm.categoryTree}})],1)]),_vm._v(" "),(_vm.isSearchOpen)?_c('SearchResults',{attrs:{"visible":_vm.isSearchOpen,"search-results":_vm.productSearchResults},on:{"close":function($event){_vm.isSearchOpen = false}}}):_vm._e(),_vm._v(" "),_c('SfOverlay',{attrs:{"visible":_vm.isSearchOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }