/* eslint-disable  */

export default {
  "Are you sure you would like to remove this item from the shopping cart?": "Tem certeza de que deseja remover este item do carrinho de compras",
  "{0} has been successfully removed from your cart":"{0} foi removido com sucesso do seu carrinho}", 
  "cart": "Adicionar ao carrinho",
  "Add a review": "Adicione uma avaliação",
  "About us": "Sobre nós",
  "Account": "Conta",
  "Add new address": "Adicionar novo endereço",
  "Add to compare": "Adicionar para comparação",
  "Add to Wishlist": "Adicionar à Lista de Desejos",
  "Additional Information": "Informações Adicionais",
  "All Orders": "Todos os Pedidos",
  "Allow order notifications": "Permitir notificações de pedidos",
  "Apply": "Aplicar",
  "Applied Coupon": "Cupom aplicado",
  "Attention!": "Atenção!",
  "Back to home": "Voltar para a página inicial",
  "Back to homepage": "Voltar para a página inicial",
  "Billing": "Cobrança",
  "Billing address": "Endereço de cobrança",
  "Brand": "Marca",
  "Cancel": "Cancelar",
  "Cart": "Carrinho",
  "Categories": "Categorias",
  "Change": "Alterar",
  "Change password your account": "Se você deseja alterar a senha de acesso à sua conta, insira as seguintes informações",
  "Clear all": "Limpar tudo",
  "Color": "Cor",
  "Commercial information": "e concordo em receber informações comerciais personalizadas da marca por e-mail",
  "Contact details updated": "Mantenha seus endereços e detalhes de contato atualizados.",
  "Contact us": "Entre em contato",
  "Continue to billing": "Continuar para pagamento",
  "Continue to payment": "Continuar para pagamento",
  "Continue to shipping": "Continuar para envio",
  "Cookies Policy": "Política de Cookies",
  "Create an account": "Criar uma conta",
  "Customer Reviews": "Avaliações de Clientes",
  "Customer service": "Atendimento ao cliente",
  "Date": "Data",
  "Default Billing Address": "Endereço de Cobrança Padrão",
  "Default Shipping Address": "Endereço de Envio Padrão",
  "Delete": "Excluir",
  "Departments": "Departamentos",
  "Description": "Descrição",
  "Details and status orders": "Verifique os detalhes e o status dos seus pedidos na loja online. Você também pode cancelar seu pedido ou solicitar uma devolução.",
  "Discount": "Desconto",
  "Done": "Concluído",
  "Download": "Baixar",
  "Download all": "Baixar todos",
  "Edit": "Editar",
  "Email address": "Endereço de e-mail",
  "Empty": "Parece que você ainda não adicionou nenhum item ao carrinho. Comece a comprar para preenchê-lo.",
  "Empty bag": "Sacola vazia",
  "Enjoy your free account": "Aproveite os benefícios de sua conta gratuita!",
  "Enter promo code": "Digite o código promocional",
  "Feedback": "Seu feedback é importante para nós. Deixe seus comentários! Obrigada.",
  "Feel free to edit": "Sinta-se à vontade para editar qualquer um dos seus detalhes abaixo para que sua conta esteja sempre atualizada",
  "Filters": "Filtros",
  "Find out more": "Saiba mais",
  "First Name": "Primeiro Nome",
  "Forgot Password": "Para redefinir sua senha, digite o email cadastrado para fazer login.",
  "Forgot Password Modal Email": "E-mail que você está usando para fazer login:",
  "forgotPasswordConfirmation": "Se houver uma conta registrada com o e-mail {0},  você receberá um link para redefinição de senha na caixa de entrada.",
  "Forgotten password?": "Esqueceu a senha?",
  "Go back": "Voltar",
  "Go back shopping": "Voltar para compras",
  "Go back to shop": "Voltar para a loja",
  "Go to checkout": "Finalizar pedido",
  "Go to cart": "Ir para o carrinho",
  "Guarantee": "Garantia",
  "Help": "Ajuda",
  "Help & FAQs": "Ajuda e Perguntas Frequentes",
  "hide": "esconder",
  "Home": "Página Inicial",
  "I agree to": "Eu concordo com",
  "I confirm subscription": "Eu confirmo a assinatura",
  "I want to create an account": "Eu quero criar uma conta",
  "Info after order": "Acesse sua conta usando o e-mail e a senha definidos anteriormente. Em sua conta, você poderá editar seus dados de perfil, verificar o histórico de pedidos e editar a assinatura da Newsletter.",
  "Instruction1": "Cuide de mim",
  "Instruction2": "Aqui apenas para as instruções de cuidado?",
  "Instruction3": "Sim, nós pensamos assim",
  "It was not possible to request a new password, please check the entered email address.": "Não foi possível solicitar uma nova senha, verifique o endereço de e-mail inserido.",
  "Item": "Item",
  "Items": "Itens",
  "Kidswear": "Moda Infantil",
  "Last Name": "Sobrenome",
  "Let’s start now – we’ll help you": "Vamos começar agora - nós vamos te ajudar",
  "Log into your account": "Faça login na sua conta",
  "Login": "Entrar",
  "login in to your account": "entrar em sua conta",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Parece que você ainda não adicionou nenhum item à sacola. Comece a fazer compras para preenchê-la.",
  "Looks like you haven’t added any items to the Wishlist.": "Parece que você ainda não adicionou nenhum item à Lista de Desejos.",
  "Make an order": "Confirmar pedido",
  "Manage addresses": "Gerencie todos os endereços que você deseja (local de trabalho, endereço residencial...). Dessa forma, você não precisará inserir o endereço manualmente a cada pedido.",
  "Manage billing addresses": "Gerencie todos os endereços de cobrança que você deseja (local de trabalho, endereço residencial...). Dessa forma, você não precisará inserir o endereço de cobrança manualmente a cada pedido.",
  "Manage shipping addresses": "Gerencie todos os endereços de envio que você deseja (local de trabalho, endereço residencial...). Dessa forma, você não precisará inserir o endereço de envio manualmente a cada pedido.",
  "Match it with": "Combine com",
  "Men fashion": "Moda Masculina",
  "Menu": "Menu",
  "My billing and shipping address are the same": "Meu endereço de cobrança e envio são os mesmos",
  "My Cart": "Meu Carrinho",
  "No account": "Ainda não tem uma conta?",
  "or": "ou",
  "or fill the details below": "ou preencha os detalhes abaixo",
  "Order ID": "ID do Pedido",
  "Order information": "Informações do Pedido",
  "Order No.": "Nº do Pedido",
  "Order summary": "Resumo do Pedido",
  "Other products you might like": "Outros produtos que você pode gostar",
  "Out of stock": "Esgotado",
  "Password": "Senha",
  "Password Changed": "Senha alterada com sucesso. Agora você pode voltar para a página inicial e fazer login.",
  "Pay for order": "Pagar pelo pedido",
  "Payment": "Pagamento",
  "Payment & Delivery": "Pagamento e Entrega",
  "Payment Method": "Método de Pagamento",
  "Payment Methods": "Métodos de Pagamento",
  "Personal details": "Detalhes pessoais",
  "Please type your current password to change your email address.": "Digite sua senha atual para alterar seu endereço de e-mail.",
  "Price": "Preço",
  "Privacy": "Privacidade",
  "Privacy Policy": "Política de Privacidade",
  "Product": "Produto",
  "Product suggestions": "Sugestões de Produtos",
  "Products": "Produtos",
  "Products found": "Produtos encontrados",
  "Purchase terms": "Termos de Compra",
  "Quality in the details": "Qualidade nos detalhes",
  "Quantity": "Quantidade",
  "Read all reviews": "Leia todas as avaliações",
  "Read and understand": "Li e entendi o",
  "Read reviews": "Ler avaliações",
  "Register": "Registrar",
  "Register today": "Registre-se hoje",
  "Remove": "Remover",
  "Remove Address": "Remover Endereço",
  "Remove from Wishlist": "Remover da Lista de Desejos",
  "Repeat Password": "Repetir Senha",
  "Reset Password": "Redefinir Senha",
  "Review my order": "Revisar meu pedido",
  "Same as shipping address": "Mesmo que o endereço de envio",
  "Save changes": "Salvar alterações",
  "Save for later": "Salvar para depois",
  "Save Password": "Salvar Senha",
  "Search": "Buscar",
  "Search for items": "Buscar por itens",
  "Search results": "Resultados da busca",
  "Sections that interest you": "Seções que interessam a você",
  "See all results": "Ver todos os resultados",
  "See more": "Ver mais",
  "Select payment method": "Selecionar método de pagamento",
  "Select shipping method": "Selecionar método de envio",
  "Send my feedback": "Enviar meu feedback",
  "Set up newsletter": "Configure seu boletim informativo e enviaremos informações sobre novos produtos e tendências das seções que você selecionou todas as semanas.",
  "Share your look": "Compartilhe seu visual",
  "Shipping": "Envio",
  "Shipping address": "Endereço de envio",
  "Shipping details": "Detalhes de envio",
  "Shipping method": "Método de envio",
  "Show":"Mostrar",
  "show more": "mostrar mais",
  "Show on page": "Mostrar na página",
  "Sign in": "Entrar",
  "Size guide": "Guia de Tamanhos",
  "Sign Up for Newsletter": "Inscreva-se para receber o boletim informativo",
  "Sort by": "Ordenar por",
  "Sort: Default": "Padrão",
  "Sort: Name A-Z": "Nome A-Z",
  "Sort: Name Z-A": "Nome Z-A",
  "Sort: Price from high to low": "Preço do mais alto para o mais baixo",
  "Sort: Price from low to high": "Preço do mais baixo para o mais alto",
  "Start shopping": "Começar a comprar",
  "Status": "Status",
  "Subscribe": "Inscrever-se",
  "Subscribe to newsletter": "Inscreva-se para receber o boletim informativo",
  "subscribeToNewsletterModalContent": "Após se inscrever no boletim informativo, você receberá ofertas especiais e mensagens da {0} por e-mail. Não venderemos nem distribuiremos seu e-mail para terceiros a qualquer momento. Consulte nossa {1}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Preço subtotal",
  "Successful placed order": "Pedido realizado com sucesso! Você receberá um e-mail de confirmação do seu pedido detalhado e um link para acompanhar seu status.",
  "Terms and conditions": "Termos e condições",
  "Thank you": "Obrigado",
  "Thank You Inbox": " Verifique também na sua caixa de spam. Se você não receber nenhuma mensagem, tente outro endereço de email que possa ter usado para se cadastrar ou entre em contato. Atendimento: ",
  "Total": "Total",
  "Order Total": "Total do Pedido",
  "Total items": "Total de itens",
  "Total price": "Preço total",
  "Update password": "Atualizar senha",
  "Update personal data": "Atualizar dados pessoais",
  "Use your personal data": "Na Fina Ideia, damos grande importância às questões de privacidade e estamos comprometidos em proteger os dados pessoais de nossos usuários. Saiba mais sobre como cuidamos e usamos seus dados pessoais na",
  "User Account": "Conta do Usuário",
  "View": "Visualizar",
  "View details": "Ver detalhes",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Quem somos",
  "Wishlist": "Lista de Desejos",
  "Women fashion": "Moda Feminina",
  "You added {product} to your shopping cart.": "Você adicionou {product} ao seu carrinho de compras.",
  "You are not authorized, please log in": "Você não está autorizado, faça login por favor",
  "You can unsubscribe at any time": "Você pode cancelar a inscrição a qualquer momento",
  "You currently have no orders": "No momento, você não tem pedidos",
  "You haven\’t searched for items yet": "Você ainda não buscou por itens",
  "Your bag is empty": "Sua sacola está vazia",
  "Your current email address is": "Seu endereço de e-mail atual é",
  "Your email": "Seu e-mail",
  "Thank you for your order!": "Obrigada pelo seu pedido!",
 "The email field must be a valid email":"O campo de e-mail deve ser um e-mail válido",
  'You have submitted no reviews': 'Você não enviou nenhuma avaliação',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"O login da conta está incorreto ou sua conta está desativada temporariamente. Por favor, aguarde e tente novamente mais tarde.",
  "A customer with the same email address already exists in an associated website.": "Um cliente com o mesmo endereço de e-mail já existe em um site associado.",
  "Invalid email": "E-mail inválido",
  "SUMMER COLLECTION {year}": "COLEÇÃO DE VERÃO {year}",
  "Colorful summer dresses are already in store": "Vestidos coloridos de verão já estão na loja",
  "Learn more": "Saiba mais",
  "Dresses": "Vestidos",
  "Cocktail & Party": "Cocktail & Festa",
  "Linen Dresses": "Vestidos de Linho",
  "T-Shirts": "Camisetas",
  "The office life": "A vida no escritório",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Encontre vestidos de coquetel deslumbrantes e vestidos de festa para mulheres. Destaque-se em vestidos de coquetel em renda e metalizados de todas as suas marcas favoritas.",
  "Shop now": "Compre agora",
  "The Office Life": "A Vida no Escritório",
  "Summer Sandals": "Sandálias de Verão",
  "Eco Sandals": "Sandálias Ecológicas",
  "Subscribe to Newsletters": "Assine os boletins informativos",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Esteja ciente das próximas promoções e eventos. Receba presentes e ofertas especiais!",
  "Fashion to take away": "Moda para levar",
  "Download our application to your mobile": "Baixe nosso aplicativo para o seu celular",
  "Share Your Look": "Compartilhe Seu Look",
  "My Account": "Minha Conta",
  "My profile": "Meu perfil",
  "Personal Details": "Detalhes Pessoais",
  "Addresses details": "Detalhes de Endereços",
  "My newsletter": "Meu boletim informativo",
  "Log out": "Sair",
  "My reviews": "Minhas avaliações",
  "Order history": "Histórico de Pedidos",
  "Order details": "Detalhes do Pedido",
  "My wishlist": "Minha Lista de Desejos",
  "Password change": "Mudança de senha",
  "Personal data": "Dados pessoais",
  "Your e-mail": "Seu e-mail",
  "Current Password": "Senha Atual",
  "You are not authorized, please log in.": "Você não está autorizado, por favor faça o login.",
  "Go To Product": "Ir para o Produto",
  "Change to list view": "Mudar para visualização em lista",
  "Change to grid view": "Mudar para visualização em grade",
  "Returns":"Devoluções",
  "My orders": "Meus pedidos",
  "Add the address": "Adicionar o endereço",
  "Set as default shipping": "Definir como endereço de envio padrão",
  "Set as default billing": "Definir como endereço de cobrança padrão",
  "House/Apartment number": "Número da Casa/Apartamento",
  "Street Name": "Nome da Rua",
  "City": "Cidade",
  "State/Province": "Estado/Província",
  "Zip-code": "Código Postal",
  "Country": "País",
  "Phone number": "Número de telefone",
  "Please select a country first":"Por favor, selecione um país primeiro",
  "This field is required": "Este campo é obrigatório",
  "The field should have at least 2 characters": "O campo deve ter pelo menos 2 caracteres",
  "The field should have at least 4 characters": "O campo deve ter pelo menos 4 caracteres",
  "The field should have at least 8 characters": "O campo deve ter pelo menos 8 caracteres",
  "New Password": "Nova Senha",
  "New Products": "Novos Produtos",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Não há métodos de envio disponíveis para este país. Pedimos desculpas, tente com um país diferente.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Houve algum erro ao tentar buscar métodos de envio. Pedimos desculpas, tente com detalhes de envio diferentes.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Houve algum erro ao tentar selecionar este método de envio. Pedimos desculpas, tente com um método de envio diferente.",
  "We can't find products matching the selection.":"Não conseguimos encontrar produtos que correspondam à seleção.",
  'Page not found': 'Página não encontrada',
  'Back to Home page': 'Voltar para a página inicial',
  'An error occurred': 'Ocorreu um erro',
  "AllProductsFromCategory": "Todos os {categoryName}",
  "Show more": "Mostrar mais",
  "Show less": "Mostrar menos",
  "Yes": "Sim",
  "No": "Não",
  "Apply filters": "Aplicar filtros",
  "The coupon code isn't valid. Verify the code and try again.": "O código do cupom não é válido. Verifique o código e tente novamente.",
  "From": "De",
  "To": "Para",
  "Your customization": "Sua personalização",
  "Passwords don't match":"As senhas não correspondem",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"A senha deve ter pelo menos 8 caracteres e deve conter pelo menos: 1 letra maiúscula ou minúscula, 1 número ou um caractere especial (por exemplo: , . _ & ? etc)",
  "Show all products":"Mostrar todos os produtos",
  "Select previously saved address":"Selecionar endereço salvo anteriormente",
  "Enter different address":"Digite um endereço diferente",
  "You must confirm your account. Please check your email for the confirmation link.": "Você deve confirmar sua conta. Verifique seu e-mail para o link de confirmação.",
  "Payment confirmation": "Confirmação de pagamento",
  "Your order number is": "O número do seu pedido é",
  "You will receive an email with the order confirmation and tracking information.": "Você receberá um e-mail com a confirmação do pedido e informações de rastreamento.",
  "Continue shopping":"Continuar comprando",
  "Leave the payment page": "Sair da página de pagamento",
  "Please wait a moment, we are preparing your payment!": "Por favor, aguarde um momento, estamos preparando seu pagamento!",
  "To use Apple Pay, open the Wallet app on your compatible device and add your credit or debit card. When you're ready to check out in the store, select Apple Pay.": "Para usar o Apple Pay, abra o aplicativo Wallet no seu dispositivo compatível e adicione seu cartão de crédito ou débito. Quando estiver pronto para fazer o checkout na loja, selecione o Apple Pay.",
  "To use Google Pay, open the Google Pay app on your compatible device and add your credit or debit card. When you're ready to check out in the store, select Google Pay.": "Para usar o Google Pay, abra o aplicativo Google Pay no seu dispositivo compatível e adicione seu cartão de crédito ou débito. Quando estiver pronto para fazer o checkout na loja, selecione o Google Pay.",
  "To use Microsoft Pay, open the Microsoft Pay app on your compatible device and add your credit or debit card. When you're ready to check out in the store, select Microsoft Pay.": "Para usar o Microsoft Pay, abra o aplicativo Microsoft Pay no seu dispositivo compatível e adicione seu cartão de crédito ou débito. Quando estiver pronto para fazer o checkout na loja, selecione o Microsoft Pay.",
  "To use Samsung Pay, open the Samsung Pay app on your compatible device and add your credit or debit card. When you're ready to check out in the store, select Samsung Pay.": "Para usar o Samsung Pay, abra o aplicativo Samsung Pay no seu dispositivo compatível e adicione seu cartão de crédito ou débito. Quando estiver pronto para fazer o checkout na loja, selecione o Samsung Pay.",
  "Select your preferred payment method to continue.": "Selecione seu método de pagamento preferido para continuar.",
  "Pay with Apple Pay": "Pagar com Apple Pay",
  "Pay with Google Pay": "Pagar com Google Pay",
  "Pay with Microsoft Pay": "Pagar com Microsoft Pay",
  "Pay with Samsung Pay": "Pagar com Samsung Pay",
  "Your billing and shipping address are the same.": "Seu endereço de cobrança e envio são os mesmos.",
  "Sign in to your account": "Faça login na sua conta",
  "Sign in / Register": "Entrar / Registrar",
  "Are you sure you want to remove this item from your cart?": "Tem certeza de que deseja remover este item do seu carrinho?",
  "An error occurred while processing your payment. Please try again.": "Ocorreu um erro ao processar seu pagamento. Por favor, tente novamente.",
  "The payment has been cancelled. You can continue shopping or contact us for further assistance.": "O pagamento foi cancelado. Você pode continuar comprando ou entrar em contato conosco para obter mais assistência.",
  "Your order is complete": "Seu pedido está completo",
  "Thank you for your purchase! Your order is being processed and you will receive an email with the order details.": "Obrigado pela sua compra! Seu pedido está sendo processado e você receberá um e-mail com os detalhes do pedido.",
  "Your order is being processed": "Seu pedido está sendo processado",
  "Thank you for your purchase! Your order is being processed and you will receive an email with the order details and tracking information.": "Obrigado pela sua compra! Seu pedido está sendo processado e você receberá um e-mail com os detalhes do pedido e informações de rastreamento.",
  "Your order is on the way": "Seu pedido está a caminho",
  "Your order has been shipped and you will receive an email with the tracking information.": "Seu pedido foi enviado e você receberá um e-mail com as informações de rastreamento.",
  "Your order has been delivered": "Seu pedido foi entregue",
  "Your order has been successfully delivered. If you have any questions, please contact us.": "Seu pedido foi entregue com sucesso. Se você tiver alguma dúvida, entre em contato conosco.",
  "Order {orderId} details": "Detalhes do Pedido {orderId}",
  "Order placed": "Pedido feito",
  "Order confirmed": "Pedido confirmado",
  "Order shipped": "Pedido enviado",
  "Order delivered": "Pedido entregue",
  "Order status": "Status do Pedido",
  "Order details": "Detalhes do Pedido",
  "Order ID": "ID do Pedido",
  "Placed on": "Feito em",
  "Items in order": "Itens no pedido",
  "Order Total": "Total do Pedido",
  "Order Summary": "Resumo do Pedido",
  "Product Name": "Nome do Produto",
  "Product Price": "Preço do Produto",
  "Quantity": "Quantidade",
  "Subtotal": "Subtotal",
  "Shipping Cost": "Custo de Envio",
  "Tax": "Imposto",
  "Discount": "Desconto",
  "Total": "Total",
  "Shipping Method": "Método de Envio",
  "Payment Method": "Método de Pagamento",
  "Billing Address": "Endereço de Cobrança",
  "Shipping Address": "Endereço de Envio",
  "Contact Information": "Informações de Contato",
  "Email Address": "Endereço de E-mail",
  "Phone Number": "Número de Telefone",
  "Shipping Details": "Detalhes de Envio",
  "Tracking Number": "Número de Rastreamento",
  "The email address you entered is already associated with an account. Please log in or use a different email address.": "O endereço de e-mail que você inseriu já está associado a uma conta. Faça login ou use um endereço de e-mail diferente.",
  "Sorry, something went wrong. Please check your email and try again.": "Desculpe, algo deu errado. Verifique seu e-mail e tente novamente.",
  "Your review has been submitted and is awaiting moderation.": "Sua avaliação foi enviada e está aguardando moderação.",
  "You must be logged in to post a review.": "Você deve estar logado para postar uma avaliação.",
  "Rating": "Avaliação",
  "By {author} on {date}": "Por {author} em {date}",
  "Sign in with": "Entrar com",
  "Continue with": "Continuar com",
  "Or": "Ou",
  "Log in with your existing account": "Faça login com sua conta existente",
  "Log in with": "Faça login com",
  "Create a new account": "Criar uma nova conta",
  "Email": "E-mail",
  "Password": "Senha",
  "Forgot your password?": "Esqueceu sua senha?",
  "Remember me": "Lembrar de mim",
  "Log in": "Entrar",
  "Don't have an account?": "Não tem uma conta?",
  "Create an account": "Criar uma conta",
  "Welcome, {username}!": "Bem-vindo, {username}!",
  "You're logged in as {username}": "Você está logado como {username}",
  "Log out": "Sair",
  "Hello, {username}!": "Olá, {username}!",
  "Your account": "Sua conta",
  "My Profile": "Meu Perfil",
  "Change Password": "Alterar Senha",
  "Email Address": "Endereço de E-mail",
  "Update Email": "Atualizar E-mail",
  "Current Password": "Senha Atual",
  "New Password": "Nova Senha",
  "Repeat New Password": "Repetir Nova Senha",
  "Save Changes": "Salvar Alterações",
  "Back to Profile": "Voltar para o Perfil",
  "Addresses": "Endereços",
  "Default Shipping Address": "Endereço de Envio Padrão",
  "Default Billing Address": "Endereço de Cobrança Padrão",
  "Add New Address": "Adicionar Novo Endereço",
  "Edit": "Editar",
  "Delete": "Excluir",
  "Are you sure you want to delete this address?": "Tem certeza de que deseja excluir este endereço?",
  "Add New Review": "Adicionar Nova Avaliação",
  "Rating": "Avaliação",
  "Title": "Título",
  "Review": "Avaliação",
  "Submit Review": "Enviar Avaliação",
  "Your review has been submitted and is awaiting approval.": "Sua avaliação foi enviada e está aguardando aprovação.",
  "You must be logged in to submit a review.": "Você deve estar logado para enviar uma avaliação.",
  "My Orders": "Meus Pedidos",
  "Order Date": "Data do Pedido",
  "Order Number": "Número do Pedido",
  "Total Price": "Preço Total",
  "View Details": "Ver Detalhes",
  "Track Order": "Rastrear Pedido",
  "My Wishlist": "Minha Lista de Desejos",
  "You have no items in your wishlist.": "Você não tem itens na sua lista de desejos.",
  "Explore Our Catalog": "Explore Nosso Catálogo",
  "Continue Shopping": "Continuar Comprando",
  "You haven't placed any orders yet.": "Você ainda não fez nenhum pedido.",
  "Start shopping": "Comece a comprar",
  "Explore our products and start shopping.": "Explore nossos produtos e comece a comprar.",
  "Go to Homepage": "Ir para a Página Inicial",
  "Total": "Total",
  "Shipping": "Envio",
  "Tax": "Imposto",
  "Discount": "Desconto",
  "Subtotal": "Subtotal",
  "Proceed to Checkout": "Prosseguir para o Checkout",
  "Product": "Produto",
  "Price": "Preço",
  "Quantity": "Quantidade",
  "Total Price": "Preço Total",
  "Remove": "Remover",
  "Continue Shopping": "Continuar Comprando",
  "No products found": "Nenhum produto encontrado",
  "Search Results for '{query}'": "Resultados da busca por '{query}'",
  "Sort By": "Ordenar Por",
  "Default": "Padrão",
  "Name": "Nome",
  "Price": "Preço",
  "Newest": "Mais Novo",
  "Oldest": "Mais Antigo",
  "Featured": "Destaque",
  "Show {results} out of {total}": "Mostrando {results} de {total}",
  "No results": "Sem resultados",
  "Page not found": "Página não encontrada",
  "The page you're looking for does not exist.": "A página que você está procurando não existe.",
  "Back to Homepage": "Voltar para a Página Inicial",
  "Contact Us": "Contate-nos",
  "Name": "Nome",
  "Email": "E-mail",
  "Message": "Mensagem",
  "Send": "Enviar",
  "Contact Information": "Informações de Contato",
  "Phone": "Telefone",
  "Monday - Friday": "Segunda - Sexta",
  "10:00 AM - 6:00 PM": "10:00 - 18:00",
  "Saturday": "Sábado",
  "12:00 PM - 4:00 PM": "12:00 - 16:00",
  "Sunday": "Domingo",
  "Closed": "Fechado",
  "Follow Us": "Siga-nos",
  "Stay connected with us on social media": "Mantenha-se conectado conosco nas redes sociais",
  "Payment Methods": "Métodos de Pagamento",
  "We accept the following payment methods": "Aceitamos os seguintes métodos de pagamento",
  "Credit Card Number": "Número do Cartão de Crédito",
  "Expiration Date": "Data de Vencimento",
  "Security Code": "Código de Segurança",
  "Continue to Payment": "Continuar para o Pagamento",
  "Order Summary": "Resumo do Pedido",
  "Product": "Produto",
  "Total": "Total",
  "Subtotal": "Subtotal",
  "Shipping": "Envio",
  "Tax": "Imposto",
  "Discount": "Desconto",
  "Edit": "Editar",
  "Apply": "Aplicar",
  "Leave a note for your order": "Deixe um recado para o seu pedido",
  "Continue to Review": "Continuar para a Revisão",
  "I agree to the": "Eu concordo com os",
  "terms and conditions": "termos e condições",
  "Place Order": "Fazer Pedido",
  "Thank You!": "Obrigado!",
  "Your order has been placed successfully. You will receive an email with the order details.": "Seu pedido foi feito com sucesso. Você receberá um e-mail com os detalhes do pedido.",
  "Continue Shopping": "Continuar Comprando",
  "Sorry, something went wrong. Please try again later.": "Desculpe, algo deu errado. Por favor, tente novamente mais tarde.",
  "Sorry, this product is currently out of stock.": "Desculpe, este produto está esgotado no momento.",
  "Sorry, we don't have enough of that product in stock.": "Desculpe, não temos estoque suficiente desse produto.",
  "Quantity updated": "Quantidade atualizada",
  "The quantity for this product has been updated.": "A quantidade deste produto foi atualizada.",
  "Item removed": "Item removido",
  "The item has been removed from your cart.": "O item foi removido do seu carrinho.",
  "Item added": "Item adicionado",
  "The item has been added to your cart.": "O item foi adicionado ao seu carrinho.",
  "Please enter a valid email address.": "Por favor, insira um endereço de e-mail válido.",
  "Please enter a valid phone number.": "Por favor, insira um número de telefone válido.",
  "Please enter a valid postal code.": "Por favor, insira um código postal válido.",
  "Please enter a valid security code.": "Por favor, insira um código de segurança válido.",
  "Please enter a valid expiration date.": "Por favor, insira uma data de vencimento válida.",
  "Please agree to the terms and conditions.": "Por favor, concorde com os termos e condições.",
  "Please fill out all required fields.": "Por favor, preencha todos os campos obrigatórios.",
  "Invalid email address": "Endereço de e-mail inválido",
  "Invalid phone number": "Número de telefone inválido",
  "Invalid postal code": "Código postal inválido",
  "Invalid security code": "Código de segurança inválido",
  "Invalid expiration date": "Data de vencimento inválida",
  "The coupon code you entered is not valid.": "O código do cupom que você inseriu não é válido.",
  "An error occurred while processing your payment. Please try again or contact us.": "Ocorreu um erro ao processar seu pagamento. Por favor, tente novamente ou entre em contato conosco.",
  "Sorry, there was an issue fetching the shipping methods. Please try again.": "Desculpe, houve um problema ao buscar os métodos de envio. Por favor, tente novamente.",
  "Sorry, there was an issue selecting the shipping method. Please try again.": "Desculpe, houve um problema ao selecionar o método de envio. Por favor, tente novamente.",
  "Sorry, there are no shipping methods available for your location.": "Desculpe, não há métodos de envio disponíveis para o seu local.",
  "Sorry, there was an error processing your order. Please contact us for further assistance.": "Desculpe, houve um erro ao processar seu pedido. Por favor, entre em contato conosco para obter mais assistência.",
  "Sorry, there was an error updating your cart. Please try again.": "Desculpe, houve um erro ao atualizar seu carrinho. Por favor, tente novamente.",
  "Sorry, there was an error removing the item from your cart. Please try again.": "Desculpe, houve um erro ao remover o item do seu carrinho. Por favor, tente novamente.",
  "Sorry, there was an error adding the item to your cart. Please try again.": "Desculpe, houve um erro ao adicionar o item ao seu carrinho. Por favor, tente novamente.",
  "Sorry, there was an error applying the coupon. Please check the code and try again.": "Desculpe, houve um erro ao aplicar o cupom. Por favor, verifique o código e tente novamente.",
  "Sorry, there was an error updating your address. Please try again.": "Desculpe, houve um erro ao atualizar seu endereço. Por favor, tente novamente.",
  "Sorry, there was an error deleting the address. Please try again.": "Desculpe, houve um erro ao excluir o endereço. Por favor, tente novamente.",
  "Sorry, there was an error submitting your review. Please try again.": "Desculpe, houve um erro ao enviar sua avaliação. Por favor, tente novamente.",
  "Sorry, there was an error updating your profile. Please try again.": "Desculpe, houve um erro ao atualizar seu perfil. Por favor, tente novamente.",
  "Sorry, there was an error updating your password. Please try again.": "Desculpe, houve um erro ao atualizar sua senha. Por favor, tente novamente.",
  "Sorry, there was an error updating your email. Please try again.": "Desculpe, houve um erro ao atualizar seu e-mail. Por favor, tente novamente.",
  "Sorry, there was an error fetching your orders. Please try again.": "Desculpe, houve um erro ao buscar seus pedidos. Por favor, tente novamente.",
  "Sorry, there was an error fetching your wishlist. Please try again.": "Desculpe, houve um erro ao buscar sua lista de desejos. Por favor, tente novamente.",
  "Sorry, there was an error processing your payment. Please try again or use a different payment method.": "Desculpe, houve um erro ao processar seu pagamento. Por favor, tente novamente ou use um método de pagamento diferente.",
  "Sorry, there was an error updating the payment method. Please try again.": "Desculpe, houve um erro ao atualizar o método de pagamento. Por favor, tente novamente.",
  "Sorry, there was an error fetching the tracking information. Please try again later.": "Desculpe, houve um erro ao buscar as informações de rastreamento. Por favor, tente novamente mais tarde.",
  "Sorry, there was an error fetching your order details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do seu pedido. Por favor, tente novamente.",
  "Sorry, there was an error fetching your address details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do seu endereço. Por favor, tente novamente.",
  "Sorry, there was an error fetching your profile details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do seu perfil. Por favor, tente novamente.",
  "Sorry, there was an error fetching your review details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes da sua avaliação. Por favor, tente novamente.",
  "Sorry, there was an error fetching the product details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do produto. Por favor, tente novamente.",
  "Sorry, there was an error fetching the cart details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do carrinho. Por favor, tente novamente.",
  "Sorry, there was an error fetching the shipping details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do envio. Por favor, tente novamente.",
  "Sorry, there was an error fetching the payment details. Please try again.": "Desculpe, houve um erro ao buscar os detalhes do pagamento. Por favor, tente novamente.",
  "Sorry, there was an error fetching your contact information. Please try again.": "Desculpe, houve um erro ao buscar suas informações de contato. Por favor, tente novamente.",
  "Sorry, there was an error fetching the store hours. Please try again.": "Desculpe, houve um erro ao buscar os horários da loja. Por favor, tente novamente.",
  "Sorry, there was an error fetching the payment methods. Please try again.": "Desculpe, houve um erro ao buscar os métodos de pagamento. Por favor, tente novamente.",
  "Sorry, there was an error fetching the terms and conditions. Please try again.": "Desculpe, houve um erro ao buscar os termos e condições. Por favor, tente novamente.",
  "Releases": "Lançamentos",
  "Price": "Preço",
  "price": "preço",
  "Add to cart":"Adicionar ao carrinho",
  "Your cart is empty": "Seu carrinho está vazio",
  "Contact": "Contato",
  "Your Purchase":"Seu pedido",
  "Your Account":"Sua conta",
  "Primary contacts for any questions":"Qualquer dúvida sobre o seu pedido, entre em contato:",
  "What can we improve": "O que podemos melhorar",
  "Amount": "Soma",
  "Payment date": "Data de pagamento",
  "Order resume": "Resumo do pedido", 
  "Add review": "Adicionar avaliação",
};
