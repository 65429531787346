









import { SfImage } from '@storefront-ui/vue';
import {
  ref,
  defineComponent,
  useFetch,
  reactive,
  computed
} from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import { useContent } from '~/composables';

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage, SfImage },
  setup() {
    const { config } = useConfig();

    const logoSrc = '~/static/main/logo-50.png'

    const { loadBlocks } = useContent();

    const blocks = ref([]);

    const block = reactive({
        logo: null
      });

    useFetch(async () => {
      blocks.value = await loadBlocks({identifiers: ['image-logo']});
      
      block.logo = blocks.value[0].content
      console.log('logomarca', block)
    })

    const logoWidth = computed(
      () => config.value.logo_width || '35',
    );

    const logoHeight = computed(
      () => config.value.logo_height || '34',
    );

    const logoAlt = computed(
      () => config.value.logo_alt || '',
    );

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
      block
    };
  },
});
