import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e2fd111a = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _0e1b97d3 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _05566179 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _07653c76 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _ca069860 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _65cde558 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _92b24e0a = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _bb4592a8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _713f9f36 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _91132488 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _09516b48 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _162d3ab6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _2943e2ee = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _f060449c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _27cb2e36 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _5e60dd8c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _e05e7214 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _2b6d4002 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _77235d1e = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _523fd118 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _6361d63d = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _517c8bb2 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _4292e5ba = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _effbdd92 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _218022b4 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "pages/ResetPassword" */))
const _7f705770 = () => interopDefault(import('../pages/types.ts' /* webpackChunkName: "pages/types" */))
const _96651804 = () => interopDefault(import('../pages/Welcome.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/default",
    component: _e2fd111a,
    name: "home___default"
  }, {
    path: "/german",
    component: _e2fd111a,
    name: "home___german"
  }, {
    path: "/default/cart",
    component: _0e1b97d3,
    name: "cart___default"
  }, {
    path: "/default/checkout",
    component: _05566179,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _07653c76,
      name: "billing___default"
    }, {
      path: "payment",
      component: _ca069860,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _65cde558,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _92b24e0a,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _bb4592a8,
      name: "user-account___default"
    }]
  }, {
    path: "/default/Cms",
    component: _713f9f36,
    name: "Cms___default"
  }, {
    path: "/default/Contact",
    component: _91132488,
    name: "Contact___default"
  }, {
    path: "/default/customer",
    component: _09516b48,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _162d3ab6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _2943e2ee,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _f060449c,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _27cb2e36,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _5e60dd8c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _e05e7214,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _2b6d4002,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/default/reset-password",
      component: _77235d1e,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _523fd118,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _6361d63d,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/default/Faq",
    component: _517c8bb2,
    name: "Faq___default"
  }, {
    path: "/default/Home",
    component: _e2fd111a,
    name: "Home___default"
  }, {
    path: "/default/Page",
    component: _4292e5ba,
    name: "Page___default"
  }, {
    path: "/default/Register",
    component: _effbdd92,
    name: "Register___default"
  }, {
    path: "/default/ResetPassword",
    component: _218022b4,
    name: "ResetPassword___default"
  }, {
    path: "/default/types",
    component: _7f705770,
    name: "types___default"
  }, {
    path: "/default/welcome",
    component: _96651804,
    name: "welcome___default"
  }, {
    path: "/default/Welcome",
    component: _96651804,
    name: "Welcome___default"
  }, {
    path: "/german/cart",
    component: _0e1b97d3,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _05566179,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _07653c76,
      name: "billing___german"
    }, {
      path: "payment",
      component: _ca069860,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _65cde558,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _92b24e0a,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _bb4592a8,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _713f9f36,
    name: "Cms___german"
  }, {
    path: "/german/Contact",
    component: _91132488,
    name: "Contact___german"
  }, {
    path: "/german/customer",
    component: _09516b48,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _162d3ab6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _2943e2ee,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _f060449c,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _27cb2e36,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _5e60dd8c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _e05e7214,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _2b6d4002,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _77235d1e,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _523fd118,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _6361d63d,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _517c8bb2,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _e2fd111a,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _4292e5ba,
    name: "Page___german"
  }, {
    path: "/german/Register",
    component: _effbdd92,
    name: "Register___german"
  }, {
    path: "/german/ResetPassword",
    component: _218022b4,
    name: "ResetPassword___german"
  }, {
    path: "/german/types",
    component: _7f705770,
    name: "types___german"
  }, {
    path: "/german/welcome",
    component: _96651804,
    name: "welcome___german"
  }, {
    path: "/german/Welcome",
    component: _96651804,
    name: "Welcome___german"
  }, {
    path: "/default/cms/:page+",
    component: _713f9f36,
    name: "cms___default"
  }, {
    path: "/german/cms/:page+",
    component: _713f9f36,
    name: "cms___german"
  }, {
    path: "/default/:slug+",
    component: _4292e5ba,
    name: "page___default"
  }, {
    path: "/german/:slug+",
    component: _4292e5ba,
    name: "page___german"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
